import React, { lazy, Suspense, useEffect } from "react";
import ReactGA from "react-ga4";
import WebFont from "webfontloader";
import { GA_TRACKING_ID } from "./modules/core/constants/gaConstants";
import Config from "./modules/core/configs/Config";

const StoreProvider = lazy(() =>
  import("./modules/core/components/StoreProvider")
);
const MainApp = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Work Sans:400,500,600,700,900",
          "Raleway:400,500,600,700,800,900",
          "Roboto:400,500",
        ],
      },
    });
  }, []);

  useEffect(() => {
    console.log("checking variables", {
      rae: process.env.REACT_APP_ENV,
      ce: Config.ENVIRONMENT,
      rgii: ReactGA.isInitialized,
    });
  }, [ReactGA.isInitialized]);

  if (process.env.REACT_APP_ENV === "local") {
    ReactGA.initialize(GA_TRACKING_ID, {
      gtagOptions: {
        debug_mode: process.env.REACT_APP_ENV === "local",
      },
    });
  } else {
    ReactGA.initialize(GA_TRACKING_ID);
  }

  return (
    <div>
      <Suspense
        fallback={
          <div
            style={{
              display: "table",
              width: "100%",
              background:
                localStorage.getItem("darkMode") === "true"
                  ? "linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('/images/login-bg1-dark.jpg')"
                  : "linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('/images/login-bg1.jpg')",
              backgroundPosition: "right top",
              minHeight: "100vh",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        }
      >
        <StoreProvider />
      </Suspense>
    </div>
  );
};

export default MainApp;
