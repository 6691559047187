export default {
  API_URL: process.env.REACT_APP_API_URL,
  // API_URL: "http://localhost:8000/",
  ENVIRONMENT: process.env.REACT_APP_ENV,
  SHORT_ENV: process.env.REACT_APP_ENV_SHORT,
  COGNITO_URL: "https://cognito-idp.us-west-2.amazonaws.com/",
  COGNITO_AUTH_URL: process.env.REACT_APP_COGNITO_AUTH_URL,
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
  LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
  STRIP_KEY: process.env.REACT_APP_STRIPE_KEY,
  MAPBOX_KEY: process.env.REACT_APP_MAPBOX_KEY,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  BASE_DOMAIN: process.env.REACT_APP_BASE_DOMAIN,
};
