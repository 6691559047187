export enum ApiResponseStatus {
  success = "success",
  warning = "warning",
  failure = "failure",
}

export enum PlanLevel {
  view = "view",
  monitor = "monitor",
  insights = "insights",
  protect = "protect",
  family = "family",
}

export enum SubscriptionInterval {
  month = "month",
  year = "year",
}

export enum StatusState {
  idle = "idle",
  processing = "processing",
  success = "success",
  failure = "failure",
  awaiting = "awaiting",
}

export enum StepStatusIndicatorString {
  start = "start",
  end = "end",
}

export enum ShortState {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export enum LogLevelType {
  Debug,
  Info,
  Warning,
  Error,
}
