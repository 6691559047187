export const SET_SIDEBAR_DRAWER_OPEN = "SET_SIDEBAR_DRAWER_OPEN";
export const SET_GLOBAL_SEARCH_OPEN = "SET_GLOBAL_SEARCH_OPEN";
export const SET_UPLOAD_DIALOG_OPEN = "SET_UPLOAD_DIALOG_OPEN";
export const SIDEBAR_GET_STATE = {
  PROCESS: "SIDEBAR_GET_STATE",
  SUCCESS: "SIDEBAR_GET_STATE_SUCCESS",
};
export const SIDEBAR_SET_SELECTED = {
  PROCESS: "SIDEBAR_SET_SELECTED",
};
export const SIDEBAR_SET_EXPANDED = {
  PROCESS: "SIDEBAR_SET_EXPANDED",
};
export const SEND_UI_ALERT = "SEND_UI_ALERT";
export const SET_COMPANY_DETAILS_TAB = "SET_COMPANY_DETAILS_TAB";
export const ZENDESK = {
  PROCESS: "ZENDESK",
  SUCCESS: "ZENDESK_SUCCESS",
  FAIL: "ZENDESK_FAIL",
};
export const SEND_FEEDBACK = {
  PROCESS: "SEND_FEEDBACK",
  SUCCESS: "SEND_FEEDBACK_SUCCESS",
  FAIL: "SEND_FEEDBACK_FAIL",
};
export const REACT_TOUR = {
  SET_WIDTH: "REACT_TOUR_SET_WIDTH",
  SET_TYPE: "REACT_TOUR_SET_TYPE",
};
export const SET_DARKMODE = {
  PROCESS: "SET_DARKMODE",
};
export const SET_GA_DATA = "SET_GA_DATA";
