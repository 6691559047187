// import store from "../store/store";
import { isEmptyOrSpaces } from "../utils/core-utils";
import { sendUiAlert } from "../actions/portalCoreActions";
import Config from "../configs/Config";
import * as Sentry from "@sentry/react";
import { LogLevelType } from "../enums/CommonEnums";
import moment from "moment/moment";
import _ from "lodash";

export const DEFAULT_FAIL_RESPONSE_STR =
  "We are having trouble reaching Dteckt servers right now. Please contact Dteckt Support.";
export const SENTRY_FILTERED_ERRORS = [
  "Error: AxiosError: Request aborted",
  "CanceledError: Expired access token",
];
export const SENTRY_FILTERED_ERROR_NAMES = ["CanceledError", "AxiosError"];

const parseParams = (params: any[]) => {
  const stringParams = params.filter((param) => _.isString(param));
  const otherParams = params.filter((param) => !_.isString(param));
  return { strings: stringParams, others: otherParams };
};

export default class LogService {
  static isDev: boolean = Config.ENVIRONMENT === "local";

  static debug(message?: string, ...optionalParams: any[]) {
    if (LogService.isDev) {
      const { strings, others } = parseParams(optionalParams);
      LogService.write(LogLevelType.Debug, message, strings, ...others);
    }
  }

  static info(message?: string, ...optionalParams: any[]) {
    const { strings, others } = parseParams(optionalParams);
    LogService.write(LogLevelType.Info, message, strings, ...others);
  }

  static warning(message?: string, ...optionalParams: any[]) {
    const { strings, others } = parseParams(optionalParams);
    LogService.write(LogLevelType.Warning, message, strings, ...others);
  }

  static error(
    message?: string,
    error?: Error | any,
    ...optionalParams: any[]
  ) {
    if (error && !LogService.isDev) {
      Sentry.captureException(error);
    }
    const { strings, others } = parseParams(optionalParams);
    LogService.write(LogLevelType.Error, message, error, strings, ...others);
  }

  static logSentryError(
    error: any,
    uiErrorMessage = DEFAULT_FAIL_RESPONSE_STR,
    extraContext: any = null
  ) {
    // const state = store.getState();
    // const { currentAlert, tokenExpired } = {
    //   currentAlert: state?.portalCore.currentAlert,
    //   tokenExpired: state.authUser.tokenExpired,
    // };
    if (
      !isEmptyOrSpaces(uiErrorMessage) &&
      !SENTRY_FILTERED_ERRORS.includes(error?.message) &&
      !SENTRY_FILTERED_ERROR_NAMES.includes(error?.name)
    ) {
      const actionPayload = sendUiAlert({
        severity: "error",
        message: uiErrorMessage,
        variant: "filled",
      });
      // if (!currentAlert?.open && !tokenExpired) {
      //   store.dispatch(actionPayload);
      // }
    }

    if (LogService.isDev && error) {
      const logMessage =
        SENTRY_FILTERED_ERRORS.includes(error?.message) ||
        SENTRY_FILTERED_ERROR_NAMES.includes(error?.name)
          ? "IGNORED - encountered exception \n"
          : "encountered exception \n";
      this.error(logMessage, error, {
        name: error?.name,
        message: error?.message,
        type: typeof error,
        extraContext,
      });
      LogService.trace("encountered exception - trace");
    }
    if (
      !SENTRY_FILTERED_ERRORS.includes(error?.message) &&
      error
      // && !tokenExpired
    ) {
      if (extraContext) {
        Sentry.captureException(error, { contexts: extraContext });
      } else {
        Sentry.captureException(error);
      }
    }
  }

  static write(
    level: LogLevelType,
    message?: string,
    stringParams?: string[],
    ...otherParams: any[]
  ) {
    const now = moment().format("HH:mm:ss:SSS");
    switch (level) {
      case LogLevelType.Debug:
        console.log(
          `%c${now} [DEBUG] %c${message} ${stringParams?.join(" ")}`,
          "color: #808080",
          "color: #868c95",
          ...otherParams
        );
        break;
      case LogLevelType.Info:
        console.log(
          `%c${now} %c[INFO]`,
          "color: #808080",
          "color: #66beed",
          message,
          ...stringParams,
          ...otherParams
        );
        break;
      case LogLevelType.Warning:
        console.warn(
          `%c${now}`,
          "color: #808080",
          `[WARNING] ${message} ${stringParams?.join(" ")}`,
          ...otherParams
        );
        break;
      case LogLevelType.Error:
        console.error(
          `%c${now}`,
          "color: #808080",
          `[ERROR] ${message} ${stringParams?.join(" ")}`,
          ...otherParams
        );
        break;
      default:
        break;
    }
  }

  static trace(message: any) {
    if (LogService.isDev) {
      const now = moment().format("HH:mm:ss:SSS");
      console.trace(
        `%c${now} %c${message}`,
        "color: #808080",
        "color: #868c95"
      );
    }
  }

  static serializeObjects(objects: any[], indent = 2) {
    return objects.map((object) => {
      try {
        return JSON.stringify(object, undefined, indent);
      } catch (error) {
        return object;
      }
    });
  }
}
