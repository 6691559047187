import {
  REACT_TOUR,
  SEND_FEEDBACK,
  SEND_UI_ALERT,
  SET_COMPANY_DETAILS_TAB,
  SET_DARKMODE,
  SET_GA_DATA,
  SET_GLOBAL_SEARCH_OPEN,
  SET_SIDEBAR_DRAWER_OPEN,
  SET_UPLOAD_DIALOG_OPEN,
  SIDEBAR_GET_STATE,
  SIDEBAR_SET_EXPANDED,
  SIDEBAR_SET_SELECTED,
  ZENDESK,
} from "../actionTypes/portalCoreTypes";

export const setSidebarDrawerOpen = (open) => ({
  type: SET_SIDEBAR_DRAWER_OPEN,
  open,
});
export const setGlobalSearchOpen = (open) => ({
  type: SET_GLOBAL_SEARCH_OPEN,
  open,
});
export const setUploadDialogOpen = (open) => ({
  type: SET_UPLOAD_DIALOG_OPEN,
  open,
});
export const sidebarGetState = (data) => ({
  type: SIDEBAR_GET_STATE.PROCESS,
  payload: data,
});
export const sidebarGetStateSuccess = (data) => ({
  type: SIDEBAR_GET_STATE.SUCCESS,
  payload: data,
});

export const sidebarSetSelected = (data) => ({
  type: SIDEBAR_SET_SELECTED.PROCESS,
  payload: data,
});

export const sidebarSetExpanded = (data) => ({
  type: SIDEBAR_SET_EXPANDED.PROCESS,
  payload: data,
});

export const sendUiAlert = ({
  severity,
  message,
  open = true,
  autoclose = 5000,
  variant = "standard",
  persistent = false,
  additionalParams = {},
}) => ({
  type: SEND_UI_ALERT,
  open,
  severity,
  message,
  autoclose,
  variant,
  persistent,
  additionalParams,
});

export const setCompanyDetailsTab = (tab) => ({
  type: SET_COMPANY_DETAILS_TAB,
  tab,
});
export const getZendeskJwt = (data) => ({
  type: ZENDESK.PROCESS,
  payload: data,
});
export const getZendeskJwtSuccess = ({ zendesk_jwt }) => ({
  type: ZENDESK.SUCCESS,
  zendeskJwt: zendesk_jwt,
});
export const getZendeskJwtFail = (message) => ({
  type: ZENDESK.FAIL,
  message,
});
export const sendFeedback = (data) => ({
  type: SEND_FEEDBACK.PROCESS,
  payload: data,
});
export const sendFeedbackSuccess = (data) => ({
  type: SEND_FEEDBACK.SUCCESS,
  payload: data,
});
export const sendFeedbackFail = (message) => ({
  type: SEND_FEEDBACK.FAIL,
  payload: message,
});
export const reactTourSetWidth = (width) => ({
  type: REACT_TOUR.SET_WIDTH,
  width,
});
export const reactTourSetType = (tourType) => ({
  type: REACT_TOUR.SET_TYPE,
  tourType,
});
export const setDarkMode = (data) => ({
  type: SET_DARKMODE.PROCESS,
  payload: data,
});
export const setGAData = (payload) => ({
  type: SET_GA_DATA,
  payload,
});
