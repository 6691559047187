import { createRoot } from "react-dom/client";
import React from "react";
import MainApp from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
    <MainApp />
  </DevSupport>
);
